<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <!-- <Header title="輸入授權序號" backto="/user" /> -->
        <div class="head mb-5">
          <div class="justify-content-center row">
            <div class="col-lg-7 p-0">
              <ProgressHeader :currentStep="2" />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="payFormBlock">
              <div class="tabBar">
                <div class="row align-items-center text-center">
                  <div class="col p-0">
                    <div class="tab" @click="$router.replace('/payment/order')">
                      <div>用信用卡支付</div>
                    </div>
                  </div>
                  <div class="col p-0">
                    <div class="tab selected">
                      <div>
                        <div>序號開通</div>
                        <div class="small">(律師好神)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center my-3">
                <div class="col">
                  <div class="p-3">
                    <h6>授權序號</h6>
                    <b-form-input
                      id="code"
                      type="text"
                      v-model="$v.code.$model"
                      :state="validateState('code')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="d-flex">
        <div class="w-100" @click="onSubmit">
          <Button class="highlight" name="確認開通"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { parseISO, format } from 'date-fns';
import { plan } from '@/mixins';
import Button from '@/elements/Button.vue';
import ProgressHeader from '@/components/ProgressHeader.vue';

export default {
  mixins: [plan, validationMixin],
  computed: {
    ...mapState({
      records: (state) => state.records.records,
    }),
  },
  data() {
    return {
      code: '',
      blocker: false,
    };
  },
  components: {
    Button,
    ProgressHeader,
  },
  validations: {
    code: { required },
  },
  methods: {
    parseISO,
    format,
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onSubmit(e) {
      e.preventDefault();
      if (this.blocker) {
        return;
      }
      this.blocker = true;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.addPlan({
        licenseKey: this.code,
        onSuccess: () => {
          this.$router.replace('/');
        },
        onFailed: () => {
          alert('此序號無法使用');
          this.blocker = false;
        },
      });
    },
  },
};
</script>

<style>
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.blocker {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 999;
  background-color: black;
  opacity: 0.6;
}
</style>
